import styled from '@emotion/styled';
import Image from 'next/image';
import { useIsMobile } from '@hooks/responsive/useIsMobile';

// components
import Button from '@components/_atoms/Button';
import Typography from '@components/_atoms/Typography';

// constants
import { TypoVariant } from '@constants/atoms';
import { Keyframes } from '@constants/keyframes';
import { LAPTOP_MODE } from '@constants/size';
import { gray, primary } from '@styles/Colors';
import Link from 'next/link';
import { SELLER } from '@constants/routes/routes';
import { useTranslation } from 'next-i18next';

const Content = styled.div`
  max-width: 1240px;
  margin: 0 auto;

  display: grid;
  grid-template-columns: 1fr 564px;
  column-gap: 167px;

  @media ${LAPTOP_MODE} {
    display: flex;
    flex-direction: column;
  }
`;

const DescriptionBox = styled.div`
  padding: 160px 40px 0;
  max-width: 445px;

  @media ${LAPTOP_MODE} {
    padding-top: 0px;
  }
`;

const Title = styled.div``;

const SubTitle = styled.div`
  margin-top: 8px;
`;

const Description = styled.div`
  margin-top: 16px;
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;
`;

const SliderWrapper = styled.div`
  height: 1000px;
  overflow: hidden;

  display: flex;
  flex-direction: column;

  @media ${LAPTOP_MODE} {
    height: 512px;
    flex-direction: row;
    margin-top: 40px;
  }
`;

const ImageBox = styled.div`
  animation: ${Keyframes.ColumnSlider} 33s linear infinite;

  @media ${LAPTOP_MODE} {
    display: flex;
    flex-wrap: nowrap;
    animation: ${Keyframes.RowSlider} 33s linear infinite;
  }
`;

const ImageCloneBox = styled(ImageBox)`
  animation: ${Keyframes.ColumnSliderClone} 33s linear infinite;

  @media ${LAPTOP_MODE} {
    animation: ${Keyframes.RowSliderClone} 33s linear infinite;
  }
`;

const ImageWrapper = styled.div`
  width: 376px;

  &:nth-child(2n) {
    float: right;
  }
`;

export default function Section3() {
  const isMobile = useIsMobile();
  const { t } = useTranslation('home');

  return (
    <Content>
      <DescriptionBox>
        <Title>
          <Typography variant={isMobile ? TypoVariant.B2 : TypoVariant.B1} color={gray.gray7}>
            {t('section3.title')}
          </Typography>
        </Title>
        <SubTitle>
          <Typography variant={isMobile ? TypoVariant.SH1 : TypoVariant.H5} color={primary.gray}>
            {t('section3.subTitle')}
          </Typography>
        </SubTitle>
        <Description>
          <Typography variant={TypoVariant.B3} color={gray.gray7}>
            {t('section3.content')}
          </Typography>
        </Description>
        <ButtonWrapper>
          <Link href={SELLER}>
            <a>
              <Button text={t('section3.button')} />
            </a>
          </Link>
        </ButtonWrapper>
      </DescriptionBox>

      <SliderWrapper>
        <ImageBox>
          <Images />
        </ImageBox>
        <ImageCloneBox>
          <Images />
        </ImageCloneBox>
      </SliderWrapper>
    </Content>
  );

  function Images() {
    return (
      <>
        <ImageWrapper>
          <Image src="/assets/images/Section3-1.webp" width={376} height={408} alt="Section3-1" />
        </ImageWrapper>
        <ImageWrapper>
          <Image src="/assets/images/Section3-2.webp" width={376} height={484} alt="Section3-2" />
        </ImageWrapper>
        <ImageWrapper>
          <Image src="/assets/images/Section3-3.webp" width={376} height={512} alt="Section3-3" />
        </ImageWrapper>
        <ImageWrapper>
          <Image src="/assets/images/Section3-4.webp" width={376} height={408} alt="Section3-4" />
        </ImageWrapper>
        <ImageWrapper>
          <Image src="/assets/images/Section3-5.webp" width={376} height={332} alt="Section3-5" />
        </ImageWrapper>
        <ImageWrapper>
          <Image src="/assets/images/Section3-6.webp" width={376} height={512} alt="Section3-6" />
        </ImageWrapper>
      </>
    );
  }
}
