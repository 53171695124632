import { useTranslation } from 'next-i18next';
import Head from 'next/head';
import { useRouter } from 'next/router';

type SEOProps = {
  readonly title?: string;
  readonly description?: string;
  readonly keywords?: string;
  readonly imgsrc?: string;
  readonly structuredData?: string;
};

export default function DefaultSEO({ title, description, keywords, imgsrc, structuredData }: SEOProps) {
  const { t, i18n } = useTranslation('common');
  const ko = i18n.language === 'ko';

  const router = useRouter();
  const location = router.asPath;
  const steelbosoUrl = ko ? 'https://steelboso.com/' : 'https://steelboso.com/en';
  const currentUrl = steelbosoUrl + location;

  const DEFAULT_TITLE = t('global.title');
  const DEFAULT_DESCRIPTION = t('global.description');
  const DEFAULT_KEYWORDS =
    '마켓오브메테리얼,marketofmaterial,배관,파이프,수도배관자재,배관자재종류,피팅,배관자재,각파이프,파이프규격,피팅,철강,니플,레듀샤,체크밸브,후렌지,각파이프규격,엘보,스텐파이프,Flange,plant,PIPE,프렌지,VALVE,SUS파이프,배관재,배관재 업체,배관재 견적,배관재 공급업체,배관재 온라인몰,배관재 온라인 거래,배관재 쇼핑몰,배관재 제작업체,배관재 납품업체,배관재 사는곳,Piping material,Piping material';
  const OG_IMAGE = 'https://steelboso.com/assets/images/logo-og.jpg';

  return (
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />

      <title>{title ? title : DEFAULT_TITLE}</title>
      <meta name="title" content={title ? title : DEFAULT_TITLE} />
      <meta name="description" content={description ? description : DEFAULT_DESCRIPTION} />
      <meta name="keywords" content={keywords ? keywords : DEFAULT_KEYWORDS} />
      <script type="application/ld+json">{structuredData}</script>

      <meta property="og:type" content="website" />
      <meta property="og:title" content={title ? title : DEFAULT_TITLE} />
      <meta property="og:description" content={description ? description : DEFAULT_DESCRIPTION} />
      <meta property="og:url" content={currentUrl} />
      <meta property="og:image" content={imgsrc ? imgsrc : OG_IMAGE} />

      <link rel="canonical" href={currentUrl} />
    </Head>
  );
}
