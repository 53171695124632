import styled from '@emotion/styled';
import Link from 'next/link';
import { useIsMobile } from '@hooks/responsive/useIsMobile';
import { useTranslation } from 'next-i18next';

// components
import Typography from '@components/_atoms/Typography';
import Button from '@components/_atoms/Button';

// constants
import { MOBILE_MODE, TABLET_MODE } from '@constants/size';
import { ButtonColor, TypoVariant } from '@constants/atoms';
import { white } from '@styles/Colors';
import {
  BUYER,
  LADING_IMAGE_FILE,
  LANDING_VIDEO_FILE_MP4,
  LANDING_VIDEO_FILE_WEBM,
  SELLER,
} from '@constants/routes/routes';

// Lottie animation
import Lottie from 'lottie-react';
import ArrowLottie from '@public/assets/lottie/arrow.json';

type Props = {
  handleArrowClick: () => void;
};

const Layout = styled.section`
  width: 100%;
  width: 100dvw;
  /* min-height: 724px; */
  height: 100vh;
  height: 100dvh;
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    min-height: -webkit-fill-available;
  }

  position: relative;
  display: flex;
  align-items: center;
`;

const BackgroundWrapper = styled.div`
  width: 100%;
  width: 100dvw;
  height: 100%;
  height: 100dvh;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  overflow: hidden;
`;

const BackgroundVideo = styled.video`
  min-width: 100%;
  min-height: 100%;
`;

const Content = styled.div`
  width: 100%;
  max-width: 1160px;
  margin: 0 auto;
  padding: 0 40px;

  @media ${TABLET_MODE} {
    max-width: 768px;
    padding: 0 40px;
  }

  @media ${MOBILE_MODE} {
    max-width: 320px;
    padding: 0;
  }
`;

const DescriptionBox = styled.div`
  display: grid;
  row-gap: 8px;
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;

  display: flex;
  column-gap: 16px;

  @media ${MOBILE_MODE} {
    flex-direction: column;
    row-gap: 8px;
  }
`;

const Title = styled.h1`
  color: white;
  font-size: 20px;

  @media ${MOBILE_MODE} {
    font-size: 18px;
  }
`;

const ArrowLottieWrapper = styled.div`
  cursor: pointer;

  width: 96px;
  height: 96px;

  position: absolute;
  top: 87.5%;
  left: 50%;
  transform: translateX(-50%);
`;

export default function MainSection({ handleArrowClick }: Props) {
  const isMobile = useIsMobile();
  const { t } = useTranslation('home');

  return (
    <Layout>
      <BackgroundWrapper>
        <BackgroundVideo autoPlay loop muted playsInline poster={LADING_IMAGE_FILE}>
          <source src={LANDING_VIDEO_FILE_WEBM} type="video/webm" />
          <source src={LANDING_VIDEO_FILE_MP4} type="video/mp4" />
        </BackgroundVideo>
      </BackgroundWrapper>

      <Content>
        <DescriptionBox>
          <Typography variant={isMobile ? TypoVariant.H5 : TypoVariant.H3} color={white}>
            {t('main.title')}
          </Typography>
          <Title>{t('main.subTitle')}</Title>
        </DescriptionBox>

        <ButtonWrapper>
          <Link href={BUYER}>
            <a>
              <Button color={ButtonColor.OutlinedWhite} text={t('main.button1')} arrow />
            </a>
          </Link>
          <Link href={SELLER}>
            <a>
              <Button color={ButtonColor.OutlinedWhite} text={t('main.button2')} arrow />
            </a>
          </Link>
        </ButtonWrapper>
      </Content>

      <ArrowLottieWrapper onClick={handleArrowClick}>
        <Lottie animationData={ArrowLottie} />
      </ArrowLottieWrapper>
    </Layout>
  );
}
