import styled from '@emotion/styled';

import { useGetBadgeColor } from '@hooks/atoms/useGetBadgeColor';

import Typography from '../Typography';

// constants
import { BadgeSize, TypoVariant } from '@constants/atoms';
import { BadgeColor } from '@constants/atoms';
import { Radius } from '@styles/Radius';
import { useMemo } from 'react';

type Props = Styleable & {
  readonly text?: string;
  readonly color?: BadgeColor;
  readonly onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  readonly size?: BadgeSize;
};

type CssProps = {
  backgroundColor: string;
  padding: string;
};

const BdageWrapper = styled.button<CssProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ padding }) => padding};

  border: none;
  border-radius: ${Radius.SMALL};
  background-color: ${({ backgroundColor }) => backgroundColor};
`;
const Text = styled(Typography)``;

export default function Badge({ text, color = BadgeColor.Blue, onClick, size = BadgeSize.Medium }: Props) {
  const [backgroundColor, textColor] = useGetBadgeColor(color);

  const [padding, typoVariant] = useMemo(() => {
    switch (size) {
      case BadgeSize.Large:
        return ['5px 14px', TypoVariant.SH3];
      case BadgeSize.Medium:
        return ['2px 10px', TypoVariant.SH4];
      case BadgeSize.Small:
        return ['1px 6px', TypoVariant.SH5];
    }
  }, [size]);

  return (
    <BdageWrapper backgroundColor={backgroundColor} padding={padding} onClick={onClick}>
      <Text variant={typoVariant} color={textColor}>
        {text}
      </Text>
    </BdageWrapper>
  );
}
