import styled from '@emotion/styled';
import Link from 'next/link';
import { useIsMobile } from '@hooks/responsive/useIsMobile';

// components
import Typography from '@components/_atoms/Typography';
import Button from '@components/_atoms/Button';

// constants
import { MOBILE_MODE } from '@constants/size';
import { SERVICE } from '@constants/routes/routes';
import { gray, primary } from '@styles/Colors';
import { TypoVariant } from '@constants/atoms';
import { useTranslation } from 'next-i18next';

const Content = styled.div`
  max-width: 644px;
  margin: 0 auto;

  @media ${MOBILE_MODE} {
    max-width: 320px;
  }
`;

const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  z-index: -1000;
  transform: translateX(-50%) translateY(-50%);
  overflow: hidden;

  position: absolute;
  top: 50%;
  left: 50%;
`;

const DescriptionBox = styled.div`
  text-align: center;

  display: grid;
  row-gap: 16px;
`;

const HighLight = styled.span`
  color: ${primary.blue};
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;

  display: flex;
  justify-content: center;
`;

export default function Section7() {
  const isMobile = useIsMobile();
  const { t, i18n } = useTranslation('home');
  const ko = i18n.language === 'ko';

  return (
    <>
      <BackgroundImage src="/assets/images/landing-bg-2.webp" alt="landing-bg" />

      <Content>
        <DescriptionBox>
          <Typography variant={isMobile ? TypoVariant.H6 : TypoVariant.H4} color={primary.gray}>
            {ko ? (
              <>
                플랜트 자재 거래는 이제
                <HighLight> 스틸보소</HighLight>와 함께
              </>
            ) : (
              <>Start trading plant materials with Steelboso</>
            )}
          </Typography>
          <Typography variant={isMobile ? TypoVariant.SH2 : TypoVariant.H6} color={gray.gray7}>
            {t('section7.subTitle')}
          </Typography>
        </DescriptionBox>

        <ButtonWrapper>
          <Link href={SERVICE}>
            <a>
              <Button text={t('section7.button')} />
            </a>
          </Link>
        </ButtonWrapper>
      </Content>
    </>
  );
}
