import { NextPage } from 'next';
import styled from '@emotion/styled';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

// compoentns
import DefaultLayout from '@components/_templates/DefaultLayout';
import MainSection from '@components/_templates/LandingSection/MainSection';
import Section0 from '@components/_templates/LandingSection/Section0';
import Section1 from '@components/_templates/LandingSection/Section1';
import Section2 from '@components/_templates/LandingSection/Section2';
import Section3 from '@components/_templates/LandingSection/Section3';
import Section4 from '@components/_templates/LandingSection/Section4';
import Section5 from '@components/_templates/LandingSection/Section5';
import Section6 from '@components/_templates/LandingSection/Section6';
import Section7 from '@components/_templates/LandingSection/Section7';

// constants
import { MOBILE_MODE, TABLET_MODE } from '@constants/size';
import { gray } from '@styles/Colors';
import { useRef } from 'react';
import { useTranslation } from 'next-i18next';
import DefaultSEO from '@components/_organism/DefaultSEO';

const LandingWrapper = styled.div``;

const Section = styled.section<{ backgroundGray?: boolean }>`
  padding: 160px 0;
  position: relative;

  @media ${TABLET_MODE} {
    padding: 120px 0;
  }

  @media ${MOBILE_MODE} {
    padding: 80px 0;
  }

  ${({ backgroundGray }) =>
    backgroundGray &&
    `
   background: ${gray.gray1};
  `}
`;

const Section3Wrapper = styled(Section)`
  padding: 0;

  @media ${TABLET_MODE} {
    padding: 120px 0;
  }

  @media ${MOBILE_MODE} {
    padding: 80px 0;
  }
`;

const Home: NextPage = () => {
  const sectionRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation('home');

  // 클릭시 Section0으로
  const handleArrowClick = () => {
    sectionRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      <DefaultSEO description={t('meta-description')} />
      <DefaultLayout>
        <LandingWrapper>
          {/* MainSection */}
          <MainSection handleArrowClick={handleArrowClick} />
          {/* MainSection end */}

          {/* 회사소개 Section */}
          <Section backgroundGray ref={sectionRef}>
            <Section0 />
          </Section>
          {/* 회사소개 Section end */}

          {/* 홍보 동영상 Section */}
          <Section>
            <Section1 />
          </Section>
          {/* 홍보 동영상 Section end */}

          {/* 거래 소개 Section */}
          <Section>
            <Section2 />
          </Section>
          {/* 거래 소개 Section end */}

          {/* 거래 소개2 Section */}
          <Section3Wrapper>
            <Section3 />
          </Section3Wrapper>
          {/* 거래 소개2 Section end */}

          {/* 거래 소개3 Section */}
          <Section backgroundGray>
            <Section4 />
          </Section>
          {/* 거래 소개3 Section end */}

          {/* 고객 후기 Section */}
          <Section>
            <Section5 />
          </Section>
          {/* 고객 후기 Section end */}

          {/* 파트너 사 Section */}
          <Section>
            <Section6 />
          </Section>
          {/* 파트너 사 Section end */}

          {/* 스틸보소 시작하기 Section */}
          <Section>
            <Section7 />
          </Section>
          {/* 스틸보소 시작하기 Section end*/}
        </LandingWrapper>
      </DefaultLayout>
    </>
  );
};

export const getStaticProps = async ({ locale }: { locale: any }) => ({
  props: {
    ...(await serverSideTranslations(locale, ['home', 'common', 'component'])),
  },
});

export default Home;
