import styled from '@emotion/styled';
import { useIsMobile } from '@hooks/responsive/useIsMobile';
import { useState } from 'react';
import { useTranslation } from 'next-i18next';

// components
import Typography from '@components/_atoms/Typography';
import Tabbar from '@components/_atoms/Tabbar';

// constants
import { MOBILE_MODE, TABLET_MODE } from '@constants/size';
import { TabbarVariant, TypoVariant } from '@constants/atoms';
import { gray, primary } from '@styles/Colors';
import { Radius } from '@styles/Radius';
import {
  BUYER_EN_MP4,
  BUYER_EN_WEBM,
  BUYER_EN_WEBP,
  BUYER_KO_MP4,
  BUYER_KO_WEBM,
  BUYER_KO_WEBP,
  MANAGE_EN_MP4,
  MANAGE_EN_WEBM,
  MANAGE_EN_WEBP,
  MANAGE_KO_MP4,
  MANAGE_KO_WEBM,
  MANAGE_KO_WEBP,
  SELLER_EN_MP4,
  SELLER_EN_WEBM,
  SELLER_EN_WEBP,
  SELLER_KO_MP4,
  SELLER_KO_WEBM,
  SELLER_KO_WEBP,
} from '@constants/routes/routes';

const Content = styled.div`
  max-width: 1080px;
  margin: 0 auto;

  @media ${TABLET_MODE} {
    max-width: 768px;
  }

  @media ${MOBILE_MODE} {
    max-width: 320px;
  }
`;

const DescriptionBox = styled.div`
  text-align: center;

  display: grid;
  row-gap: 8px;
`;

const TabbarWrapper = styled.div`
  margin-top: 16px;
`;

const HighLight = styled.span`
  color: ${primary.blue};
`;

const VideoWrapper = styled.div`
  margin-top: 40px;
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  border: 1px solid ${gray.gray3};
  border-radius: ${Radius.LARGE};
  overflow: hidden;
`;

export default function Section1() {
  const isMobile = useIsMobile();
  const { t, i18n } = useTranslation('home');
  const ko = i18n.language === 'ko';

  const label: string[] = t('section1.label', { returnObjects: true });
  const [BUYER, SELLER, MANAGE] = label;
  const [selectLabel, setSelectLabel] = useState(BUYER);

  const videoMap = {
    [BUYER]: {
      webm: ko ? BUYER_KO_WEBM : BUYER_EN_WEBM,
      mp4: ko ? BUYER_KO_MP4 : BUYER_EN_MP4,
    },
    [SELLER]: {
      webm: ko ? SELLER_KO_WEBM : SELLER_EN_WEBM,
      mp4: ko ? SELLER_KO_MP4 : SELLER_EN_MP4,
    },
    [MANAGE]: {
      webm: ko ? MANAGE_KO_WEBM : MANAGE_EN_WEBM,
      mp4: ko ? MANAGE_KO_MP4 : MANAGE_EN_MP4,
    },
  };
  const posterMap = {
    [BUYER]: ko ? BUYER_KO_WEBP : BUYER_EN_WEBP,
    [SELLER]: ko ? SELLER_KO_WEBP : SELLER_EN_WEBP,
    [MANAGE]: ko ? MANAGE_KO_WEBP : MANAGE_EN_WEBP,
  };

  const handleClickTabbar = (e: React.MouseEvent<HTMLButtonElement>) => {
    const { value } = e.target as HTMLButtonElement;
    setSelectLabel(value);
  };

  return (
    <Content>
      <DescriptionBox>
        <Typography variant={isMobile ? TypoVariant.B2 : TypoVariant.B1} color={gray.gray7}>
          {t('section1.title')}
        </Typography>
        <Typography variant={isMobile ? TypoVariant.SH1 : TypoVariant.H5} color={primary.gray}>
          {ko ? (
            <>
              <HighLight>쉽고 빠르게</HighLight> 원하는 자재를 거래해 보세요
            </>
          ) : (
            <>Experience quick and easy trading of plant materials.</>
          )}
        </Typography>
      </DescriptionBox>

      <TabbarWrapper>
        <Tabbar variant={TabbarVariant.DIVIDER} label={label} selectLabel={selectLabel} onClick={handleClickTabbar} />
      </TabbarWrapper>

      <VideoWrapper>
        <Video key={selectLabel} poster={posterMap[selectLabel]} autoPlay loop muted playsInline>
          <source src={videoMap[selectLabel].webm} type="video/webm" />
          <source src={videoMap[selectLabel].mp4} type="video/mp4" />
        </Video>
      </VideoWrapper>
    </Content>
  );
}
