import styled from '@emotion/styled';
import Image from 'next/image';
import Link from 'next/link';
import { useIsMobile } from '@hooks/responsive/useIsMobile';

// components
import Button from '@components/_atoms/Button';
import Typography from '@components/_atoms/Typography';

// constants
import { TypoVariant } from '@constants/atoms';
import { MOBILE_MODE, LAPTOP_MODE } from '@constants/size';
import { Keyframes } from '@constants/keyframes';

// hooks
import { useIO } from '@hooks/interaction/useIO';
import { useRef } from 'react';
import { gray, primary } from '@styles/Colors';
import { BUYER_SEARCH, CHANNEL_TALK } from '@constants/routes/routes';
import { useTranslation } from 'next-i18next';

const Content = styled.div`
  max-width: 1120px;
  padding: 0 40px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  row-gap: 80px;

  @media ${LAPTOP_MODE} {
    max-width: 768px;
  }

  @media ${MOBILE_MODE} {
    max-width: 320px;
    padding: 0;
  }
`;

const TopBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 660px;
  column-gap: 80px;

  @media ${LAPTOP_MODE} {
    display: flex;
    flex-direction: column;
    row-gap: 40px;
  }
`;

const BottomBox = styled.div`
  display: grid;
  grid-template-columns: 448px 1fr;
  column-gap: 160px;

  @media ${LAPTOP_MODE} {
    display: flex;
    flex-direction: column-reverse;
    row-gap: 40px;
  }
`;

const DescriptionBox = styled.div`
  max-width: 400px;
`;

const Title = styled.div``;

const SubTitle = styled.div`
  margin-top: 8px;
`;

const Description = styled.div`
  margin-top: 16px;
`;

const ButtonWrapper = styled.div`
  margin-top: 40px;
`;

const ImageWrapper = styled.div`
  margin: 0 auto;

  &[aria-hidden='false'] {
    animation: ${Keyframes.fadeInUp} 1s 0.3s both;
  }
`;

export default function Section4() {
  const isMobile = useIsMobile();
  const { t, i18n } = useTranslation('home');
  const ko = i18n.language === 'ko';

  const topRef = useRef<HTMLDivElement>(null);
  const bottomRef = useRef<HTMLDivElement>(null);

  const topObserver = useIO({ ref: topRef, options: { triggerOnce: true, threshold: 0 } });
  const bottomObserver = useIO({ ref: bottomRef, options: { triggerOnce: true, threshold: 0 } });

  return (
    <Content>
      <TopBox>
        <DescriptionBox>
          <Title>
            <Typography variant={isMobile ? TypoVariant.B2 : TypoVariant.B1} color={gray.gray7}>
              {t('section4.title1')}
            </Typography>
          </Title>
          <SubTitle>
            <Typography variant={isMobile ? TypoVariant.SH1 : TypoVariant.H5} color={primary.gray}>
              {t('section4.subTitle1')}
            </Typography>
          </SubTitle>
          <Description>
            <Typography variant={TypoVariant.B3} color={gray.gray7}>
              {t('section4.content1')}
            </Typography>
          </Description>
          <ButtonWrapper>
            <a href={CHANNEL_TALK} target="_blank" rel="noreferrer">
              <Button text={t('section4.button1')} />
            </a>
          </ButtonWrapper>
        </DescriptionBox>

        <ImageWrapper ref={topRef} aria-hidden={!topObserver}>
          <Image
            src={ko ? '/assets/images/Section4-1.webp' : '/assets/images/Section4-1-en.webp'}
            width={660}
            height={702}
            alt="Section4-1"
          />
        </ImageWrapper>
      </TopBox>

      <BottomBox>
        <ImageWrapper ref={bottomRef} aria-hidden={!bottomObserver}>
          <Image src="/assets/images/Section4-2.webp" width={448} height={560} alt="Section4-2" />
        </ImageWrapper>

        <DescriptionBox>
          <Title>
            <Typography variant={isMobile ? TypoVariant.B2 : TypoVariant.B1} color={gray.gray7}>
              {t('section4.title2')}
            </Typography>
          </Title>
          <SubTitle>
            <Typography variant={isMobile ? TypoVariant.SH1 : TypoVariant.H5} color={primary.gray}>
              {t('section4.subTitle2')}
            </Typography>
          </SubTitle>
          <Description>
            <Typography variant={TypoVariant.B3} color={gray.gray7}>
              {t('section4.content2')}
            </Typography>
          </Description>
          <ButtonWrapper>
            <Link href={BUYER_SEARCH}>
              <a>
                <Button text={t('section4.button2')} />
              </a>
            </Link>
          </ButtonWrapper>
        </DescriptionBox>
      </BottomBox>
    </Content>
  );
}
