import styled from '@emotion/styled';
import { useIsMobile } from '@hooks/responsive/useIsMobile';

// components
import Card from '@components/_atoms/Card';
import Typography from '@components/_atoms/Typography';

// constants
import { MOBILE_MODE } from '@constants/size';
import { TypoVariant } from '@constants/atoms';
import { Keyframes } from '@constants/keyframes';
import { gray, primary } from '@styles/Colors';
import { useTranslation } from 'next-i18next';

const Content = styled.div`
  max-width: 1920px;
  margin: 0 auto;
`;

const DescriptionBox = styled.div`
  margin-bottom: 40px;
  text-align: center;

  display: grid;
  row-gap: 8px;
`;

const HighLight = styled.span`
  color: ${primary.blue};
`;

const SliderWrapper = styled.div`
  overflow: hidden;
  display: flex;
`;

const CardWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  column-gap: 24px;
  padding-right: 24px;

  animation: ${Keyframes.RowSlider} 33s linear infinite;
`;

const CardCloneWrapper = styled(CardWrapper)`
  animation: ${Keyframes.RowSliderClone} 33s linear infinite;
`;

const CardContent = styled.div`
  width: 296px;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  justify-content: space-between;

  @media ${MOBILE_MODE} {
    width: 232px;
  }
`;

export default function Section5() {
  const isMobile = useIsMobile();
  const { t, i18n } = useTranslation('home');
  const ko = i18n.language === 'ko';

  return (
    <Content>
      <DescriptionBox>
        <Typography variant={isMobile ? TypoVariant.B2 : TypoVariant.B1} color={gray.gray7}>
          {t('section5.title')}
        </Typography>
        <Typography variant={isMobile ? TypoVariant.SH1 : TypoVariant.H5} color={primary.gray}>
          {ko ? (
            <>
              스틸보소의 서비스에 만족한
              <br />
              <HighLight>고객의 생생한 후기</HighLight>를 확인해 보세요
            </>
          ) : (
            <>Check out the testimonials of customers who already experiences Steelboso’s services</>
          )}
        </Typography>
      </DescriptionBox>

      <SliderWrapper>
        <CardWrapper>
          <Cards />
        </CardWrapper>
        <CardCloneWrapper>
          <Cards />
        </CardCloneWrapper>
      </SliderWrapper>
    </Content>
  );

  function Cards() {
    return (
      <>
        <Card>
          <CardContent>
            <Typography variant={TypoVariant.B3} color={primary.gray}>
              {t('section5.card1')}
            </Typography>
            <Typography variant={TypoVariant.SH4} color={primary.gray}>
              {t('section5.card1-company')}&ensp;
              <HighLight>{t('section5.card1-person')}</HighLight>
            </Typography>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Typography variant={TypoVariant.B3} color={primary.gray}>
              {t('section5.card2')}
            </Typography>
            <Typography variant={TypoVariant.SH4} color={primary.gray}>
              {t('section5.card2-company')}&ensp;
              <HighLight>{t('section5.card2-person')}</HighLight>
            </Typography>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Typography variant={TypoVariant.B3} color={primary.gray}>
              {t('section5.card3')}
            </Typography>
            <Typography variant={TypoVariant.SH4} color={primary.gray}>
              {t('section5.card3-company')}&ensp;
              <HighLight>{t('section5.card3-person')}</HighLight>
            </Typography>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Typography variant={TypoVariant.B3} color={primary.gray}>
              {t('section5.card4')}
            </Typography>
            <Typography variant={TypoVariant.SH4} color={primary.gray}>
              {t('section5.card4-company')}&ensp;
              <HighLight>{t('section5.card4-person')}</HighLight>
            </Typography>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Typography variant={TypoVariant.B3} color={primary.gray}>
              {t('section5.card5')}
            </Typography>
            <Typography variant={TypoVariant.SH4} color={primary.gray}>
              {t('section5.card5-company')}&ensp;
              <HighLight>{t('section5.card5-person')}</HighLight>
            </Typography>
          </CardContent>
        </Card>

        <Card>
          <CardContent>
            <Typography variant={TypoVariant.B3} color={primary.gray}>
              {t('section5.card6')}
            </Typography>
            <Typography variant={TypoVariant.SH4} color={primary.gray}>
              {t('section5.card6-company')}&ensp;
              <HighLight>{t('section5.card6-person')}</HighLight>
            </Typography>
          </CardContent>
        </Card>
      </>
    );
  }
}
