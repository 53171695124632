import styled from '@emotion/styled';
import { useRef } from 'react';
import { useIsMobile } from '@hooks/responsive/useIsMobile';

// components
import Badge from '@components/_atoms/Badge';
import Card from '@components/_atoms/Card';
import Typography from '@components/_atoms/Typography';

// constants
import { BadgeColor, TypoVariant } from '@constants/atoms';
import { MOBILE_MODE, TABLET_MODE } from '@constants/size';
import { gray, primary } from '@styles/Colors';
import { Keyframes } from '@constants/keyframes';

// hooks
import { useIO } from '@hooks/interaction/useIO';
import { useTranslation } from 'next-i18next';

const Content = styled.div`
  max-width: 804px;
  margin: 0 auto;

  @media ${TABLET_MODE} {
    max-width: 768px;
    padding: 0 40px;
  }

  @media ${MOBILE_MODE} {
    max-width: 320px;
    padding: 0;
  }
`;

const DescriptionBox = styled.div`
  text-align: center;

  display: grid;
  row-gap: 8px;
`;

const HighLight = styled.span`
  color: ${primary.blue};
`;

const CardWrapper = styled.div`
  margin-top: 40px;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 24px;

  @media ${TABLET_MODE} {
    grid-template-columns: none;
    row-gap: 16px;
  }
`;
const BuyerCardContainer = styled.div`
  &[aria-hidden='false'] {
    animation-name: ${Keyframes.fadeInUp};
    animation-duration: 1s;
    animation-delay: 0.3s;
    animation-fill-mode: both;
  }
`;
const SellerCardContainer = styled.div`
  &[aria-hidden='false'] {
    animation-name: ${Keyframes.fadeInUp};
    animation-duration: 1s;
    animation-delay: 0.4s;
    animation-fill-mode: both;
  }
`;
const RelatedCompaniesCardContainer = styled.div`
  &[aria-hidden='false'] {
    animation-name: ${Keyframes.fadeInUp};
    animation-duration: 1s;
    animation-delay: 0.5s;
    animation-fill-mode: both;
  }
`;

const CardContent = styled.div`
  margin-top: 8px;

  display: grid;
  row-gap: 16px;

  @media ${MOBILE_MODE} {
    margin-top: 4px;
    row-gap: 8px;
  }
`;

export default function Section2() {
  const isMobile = useIsMobile();
  const { t, i18n } = useTranslation('home');
  const ko = i18n.language === 'ko';

  const ref = useRef<HTMLDivElement>(null);
  const observer = useIO({ ref, options: { triggerOnce: true, threshold: 0 } });

  return (
    <Content>
      <DescriptionBox>
        <Typography variant={isMobile ? TypoVariant.B2 : TypoVariant.B1} color={gray.gray7}>
          {t('section2.title')}
        </Typography>
        <Typography variant={isMobile ? TypoVariant.SH1 : TypoVariant.H5} color={primary.gray}>
          {ko ? (
            <>
              구매자와 판매자 모두 <HighLight>편리한 거래</HighLight>
            </>
          ) : (
            <>Convenient transactions for both buyers and sellers.</>
          )}
        </Typography>
      </DescriptionBox>

      <CardWrapper ref={ref}>
        <BuyerCardContainer aria-hidden={!observer}>
          <Card height="100%">
            <Badge text={t('section2.buyer')} />
            <CardContent>
              <Typography variant={isMobile ? TypoVariant.SH4 : TypoVariant.SH3} color={primary.gray}>
                {t('section2.buyer-content1')}
              </Typography>
              <Typography variant={TypoVariant.B4} color={primary.gray}>
                {t('section2.buyer-content2')}
              </Typography>
            </CardContent>
          </Card>
        </BuyerCardContainer>
        <SellerCardContainer aria-hidden={!observer}>
          <Card height="100%">
            <Badge text={t('section2.seller')} color={BadgeColor.Green} />
            <CardContent>
              <Typography variant={isMobile ? TypoVariant.SH4 : TypoVariant.SH3} color={primary.gray}>
                {t('section2.seller-content1')}
              </Typography>
              <Typography variant={TypoVariant.B4} color={primary.gray}>
                {t('section2.seller-content2')}
              </Typography>
            </CardContent>
          </Card>
        </SellerCardContainer>
        <RelatedCompaniesCardContainer aria-hidden={!observer}>
          <Card height="100%">
            <Badge text={t('section2.company')} color={BadgeColor.Yellow} />
            <CardContent>
              <Typography variant={isMobile ? TypoVariant.SH4 : TypoVariant.SH3} color={primary.gray}>
                {t('section2.company-content1')}
              </Typography>
              <Typography variant={TypoVariant.B4} color={primary.gray}>
                {t('section2.company-content2')}
              </Typography>
            </CardContent>
          </Card>
        </RelatedCompaniesCardContainer>
      </CardWrapper>
    </Content>
  );
}
